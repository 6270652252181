<template>
  <div class="popup-overlay">
    <header-bar />
    <div class="container container-flexible-height">
        <sidebar-user class="sidebar" />
        <nando-eye-upload class="main-content" />
    </div>
  </div>
</template>

<script>

import HeaderBar from '@/components/HeaderBar.vue';
import NandoEyeUpload from '@/components/NandoEyeUpload.vue';
import SidebarUser from '@/components/SidebarUser.vue';

export default {
  name: 'NandoEye',
  components: {
    HeaderBar,
    SidebarUser,
    NandoEyeUpload
  },
};

</script>

<style scoped>
@import "@/css/common.css";
</style>
