<template>
  <div id="app">
      <router-view class="router-view-class"></router-view>
  </div>
</template>

<script>
export default {
  name: 'App',
  components: {
  }
};
</script>

<style>
/* Add global styles here */
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');
</style>


<style>
#app {
  font-family: 'Montserrat', 'Bebas Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #000000;
}
.router-view-class { /* Add this class to your router-view or a wrapper div */
  position: relative;
  z-index: 1; /* Ensure it's above the background */
}
</style>
