<template>
  <div class="header">
    <button class="sidebar-button" @click="$store.commit('toggleSidebar')">☰</button>
    <div class="logo-container" @click="$router.push({ name: 'Home' })">
      <img src="@/assets/logo.png" alt="Logo" class="logo" />
    </div>
    <button class="guide-button">Help</button>
  </div>
</template>

<script>
export default {
  name: "HeaderBar",
};
</script>

<style scoped>
.header {
  margin-top: 20px;
  margin-bottom: 10px;
  display: flex;
  justify-content: center; /* Center items horizontally */
  align-items: center;
  width: 100%;
  height: 50px;
  position: relative; /* Allows absolute positioning relative to the header */
}
.logo-container {
  display: flex;
  justify-content: center; /* Center the logo within the logo container */
  position: absolute; /* Position it absolutely within the header */
  left: 50%; /* Start the left edge of logo-container at the center of the header */
  transform: translateX(-50%); /* Move logo-container back by half its width */
}
.logo {
  width: 70%; /* Fixed width for the logo */
  min-width: 170px;
  max-height: 86px;
}
.guide-button {
  margin-right: 10px;
  position: absolute; /* Position the button absolutely */
  right: 1rem; /* Place it on the right with 1rem of space */
  padding: 10px 20px;
  border: none;
  border-radius: 20px;
  background-color: #800080;
  color: white;
  cursor: pointer;
  display: none;
}

.sidebar-button {
  position: absolute; /* Position the button absolutely */
  left: 1.5rem; /* Place it on the right with 1rem of space */
  padding: 10px 20px;
  border: none;
  font-weight: bold;
  border-radius: 20px;
  font-size: 2rem;
  color: white;
  cursor: pointer;
}
@media (min-width: 800px) {
  .sidebar-button {
    display: none;
  }
}
</style>
