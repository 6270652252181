let config = {};

const API_URL_PROD = "https://kkpzg3gu2f.execute-api.eu-central-1.amazonaws.com/prod/api/v1";
const API_URL_DEV = "https://k9n96s4ib4.execute-api.eu-central-1.amazonaws.com/dev/api/v1";

config.ENV =
  window.location.href.indexOf("prod") >= 0 ||
  window.location.href.indexOf("master") >= 0 ||
  window.location.href.indexOf("re-learn.eu") >= 0
    ? "PROD"
    : "DEV";

config.API_URL = config.ENV === "PROD" ? API_URL_PROD : API_URL_DEV;
config.MIXPANEL_TOKEN = "3d9e856dfa2d0e63e6dd275cc09fe656";

export { config };
