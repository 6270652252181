<template>
  <div class="popup-overlay">
    <header-bar />
    <div class="container">
        <sidebar-user class="sidebar" />
        <nando-eye-take-preview class="main-content" />
    </div>
  </div>
</template>

<script>

import SidebarUser from '@/components/SidebarUser.vue';
import NandoEyeTakePreview from '@/components/NandoEyeTakePreview.vue';
import HeaderBar from '@/components/HeaderBar.vue';

export default {
  name: 'NandoEye',
  components: {
    HeaderBar,
    SidebarUser,
    NandoEyeTakePreview
  },
};

</script>

<style scoped>
@import "@/css/common.css";
</style>
