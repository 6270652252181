<template>
  <div class="nando-eye-selection">
    <div class="content-title">
      <h1>NANDO EYE</h1>
    </div>

    <p>Upload your photo or take a picture with your webcam.</p>

    <div class="action-buttons">
      <div @click="goToUploadPage" class="selection-button">
        <img src="@/assets/upload.png" alt="Upload" class="actions-img" />
      </div>
      <div @click="goToTakePage" class="selection-button">
        <img src="@/assets/take.png" alt="Take a picture" class="actions-img" />
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: "NandoEyeSelection",
  methods: {
    goToUploadPage() {
      this.$router.push({ name: "NandoEyeUpload" });
    },
    goToTakePage() {
      this.$router.push({ name: "NandoEyeTakePreview" });
    },
  },
};
</script>

<style scoped>
p,
h1 {
  color: #000;
}

.nando-eye-selection {
  background: #fff;
  border-radius: 24px;
  display: flex;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.action-buttons {
  display: flex;
  margin: auto;
}

@media (max-width: 800px) {
  .action-buttons {
    flex-direction: column;
    max-width: 70%;
  }
}

.actions-img {
  max-height: 100%;
  max-width: 100%;
}

.selection-button {
  border: 1px solid #ccc;
  border-radius: 20px;
  /* More vertical space */
  padding: 20px;
  margin: 10px;
  flex: 1;
  text-align: center;
  cursor: pointer;
  justify-self: space-between;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

@media (max-width: 800px) {
  .selection-button {
    max-height: 45%;
  }
}

.try-it-button {
  background: #c88af9;
  color: white;
  border: none;
  padding: 10px 50px;
  border-radius: 20px;
  cursor: pointer;
  /* To match the login page button */
  width: auto;
  /* Spacing from the action buttons */
  margin-bottom: 20px;
  /* Push the action-buttons to the bottom */
  margin-top: auto;
}
</style>
