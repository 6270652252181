<template>
  <div class="nando-eye-upload-view">
    <div class="content-title">
      <div style="flex: 0.25">
        <button
          @click="$router.push({ name: 'NandoEye' })"
          class="back-button"
        ></button>
      </div>
      <div style="flex: 4.75 1 0%; margin-left: -1rem;">
        <h1>SEE THE MAGIC</h1>
      </div>
    </div>

    <p>Upload your photo or take a picture with your webcam.</p>
    <div class="spacer"></div>

    <div class="upload-area" v-if="!loading">
      <div class="upload-box">
        <h5>WHAT KIND OF<br />PICTURES TO UPLOAD</h5>
        <div class="example-container">
          <img src="/example.png" alt="Upload" class="example-img" />
          <p>Your picture</p>
        </div>
        <div class="example-container">
          <img src="/cavi.webp" alt="Upload" class="example-img" />
          <p>Web taken</p>
        </div>
      </div>
      
      <div class="vertical-line"></div>
      
      <div class="city-selector">
        <div>
          <p>Upload your image</p>
          <input
            class="input-file"
            type="file"
            id="file"
            @change="uploadImage"
          />
          <label for="file">{{ filename ? filename : "Upload a file" }}</label>
        </div>

        <div>
          <p>Choose your city</p>
          <select v-model="selectedCity">
            <option v-for="city in cities" :key="city.id" :value="city.id">
              {{ city.name }}
            </option>
          </select>
        </div>

        <div v-if="errorMessage" class="error-message">
          <p v-if="errorMessage" class="error-text">
            {{ errorMessage }}
          </p>
        </div>

        <button
          v-if="!loading"
          @click="submit"
          class="responsive-button try-it-button"
        >
          Try it
        </button>
      </div>
    </div>

    <div v-if="loading">
      <img
        class="img"
        src="@/assets/loading.gif"
        alt="loading..."
        width="250"
      />
      <h1>NANDO IS THINKING</h1>
    </div>
  </div>
</template>

<script>
import { config } from "@/config";
import { Auth } from "aws-amplify";
import axios from "axios";

export default {
  name: "NandoEyeSelection",
  data() {
    return {
      cities: [],
      selectedCity: 6,
      filename: "",
      base64Image: "",
      errorMessage: "",
      loading: false,
    };
  },
  mounted: async function () {
    this.retrieveCities();
  },
  methods: {
    uploadImage(e) {
      this.filename = e.target.files[0].name;
      const file = e.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.base64Image = e.target.result;
        };
        reader.readAsDataURL(file);
      }
    },

    async retrieveCities() {
      var endpoint = config.API_URL + "/city-list";
      var session = await Auth.currentSession();
      var token = await session.getIdToken().getJwtToken();
      axios
        .get(endpoint, {
          headers: {
            Authorization: token,
          },
        })
        .catch((error) => {
          console.log(error);
        })
        .then((response) => {
          try {
            this.cities = response.data;
          } catch (error) {
            console.log(error);
          }
        });
    },

    async submit() {
      this.errorMessage = "";
      this.loading = true;
      if (!this.base64Image) {
        this.errorMessage = "Please select an image";
        this.loading = false;
      } else if (!this.selectedCity) {
        this.errorMessage = "Please select a city";
        this.loading = false;
      } else if (this.$store.state.tokens <= 0) {
        this.errorMessage = "You do not have tokens";
        this.loading = false;
      } else {
        var endpoint = config.API_URL + "/nando-eye-detection";
        var session = await Auth.currentSession();
        var token = await session.getIdToken().getJwtToken();
        var data = {
          bin_regulation_id: this.selectedCity,
          image: this.base64Image,
        };
        axios
          .post(endpoint, data, {
            headers: {
              Authorization: token,
            },
          })
          .catch((error) => {
            console.log(error);
          })
          .then((response) => {
            try {
              this.prediction = response.data;
              this.$store.commit("setNandoEyePrediction", response.data);
              this.$store.commit("setNandoEyeImage", this.base64Image);
              this.loading = false;
              this.$router.push({ name: "NandoEyeResult" });
            } catch (error) {
              console.log(error);
              this.loading = false;
              this.errorMessage = "An error occurred during prediction";
            }
          });
      }
    },
  },
};
</script>

<style scoped>
.vertical-line {
  border-left: 1px solid #000;
  height: 80%;
}
@media (max-width: 800px) {
  .vertical-line {
    display: none;
  }
}
.nando-eye-upload-view {
  background-color: white;
  border-radius: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
  flex-direction: column;
  display: flex;
}

.upload-area {
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 1px solid black;
  border-radius: 25px;
  margin: auto;
  width: 50rem;
  min-height: 20rem;
}

@media (max-width: 1230px) {
  .upload-area {
      width: 100%;
  }
}

.upload-area p {
  font-weight: bold;
}

.upload-box {
  flex: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem; /* Padding inside the dashed box */
  flex-direction: column;
}

@media (max-width: 800px) {
  .upload-box {
    display: none;
  }
}

.example-container {
  display: flex;
  justify-content: center;
  align-items: center;
  max-height: 50%;
  width: 100%;
  margin-top: 10px;
  flex-direction: row;
  gap: 0.6rem;
}
.example-container p {
  text-align: left;
  font-weight: normal;
}

.example-img {
  /* Assuming you have an SVG or PNG icon for uploading */
  max-height: 100%; /* Full width of the container */
  max-width: 7rem;
  margin: auto;
}

.city-selector {
  flex: 4;
  display: flex;
  flex-direction: column; /* Stack children vertically */
  justify-content: center; /* Center children vertically */
  gap: 2rem;
  margin: 5%;
}

@media (max-width: 800px) {
  .city-selector {
    flex-basis: 100%;
  }
}
.city-selector select {
  width: 100%; /* Full width of the container */
  padding: 0.5rem;
  border-radius: 25px;
  text-align: center;
  border: 1px solid #000;
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.input-file {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}
.input-file + label {
  width: 100%; /* Full width of the container */
  padding: 0.5rem;
  border-radius: 25px;
  text-align: center;
  border: 1px solid #4caf4c;
  color: white;
  background-color: #4caf4c;
  display: inline-block;
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.input-file:focus + label,
label:hover {
  background-color: #1d5d38;
  border: 1px solid #1d5d38;
}

.try-it-button {
  background-color: #8c52ff; /* Example button color */
  color: white;
  align-self: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}
</style>
