<template>
  <div class="login-popup-overlay">
    <img src="@/assets/logo.png" alt="Logo" class="logo" />
    <div class="login-popup">
      <h2>Welcome</h2>

      <div>
        <p>
          Login with your email and try <b>NANDO.Eye</b><br />
          Use your credentials from the Nando Analytics Dashboard.
        </p>
        <form v-on:submit.prevent="login" class="login-form">
          <input type="email" v-model="email" placeholder="E-mail" required />
          <input
            type="password"
            v-model="password"
            placeholder="Password"
            required
          />

          <div class="checkbox-container">
            <input type="checkbox" id="privacyCheck" required />
            <label for="privacyCheck"
              >I accept the
              <a
                href="https://drive.google.com/uc?export=download&id=18nnGo0RF0r0pvNLPeST6PWEIxblwZKdf"
                >terms and privacy policy</a
              ></label
            >
          </div>

          <button v-if="!loading" type="submit" class="btn-login">Login</button>

          <div v-if="errorLogin" class="error-message">
            <span
              v-if="errorLogin"
              dense
              outlined
              dismissible
              class="error-text"
            >
              {{ errorLoginMessage }}
            </span>
          </div>

          <div class="registration-container">
            <span
              >Don't have an account?
              <a href="https://www.re-learn.eu/book-a-call/" target="_blank"
                >Click here</a
              ></span
            >
          </div>

          <v-progress-linear
            v-if="loading"
            indeterminate
            color="nandocolor"
          ></v-progress-linear>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { Auth } from "aws-amplify";

export default {
  name: "LoginPopup",
  data() {
    return {
      email: "",
      password: "",
      errorLogin: false,
      errorLoginMessage: "",
      loading: false,
    };
  },
  methods: {
    async login() {
      this.errorLogin = false;
      this.loading = true;
      try {
        const user = await Auth.signIn(this.email, this.password);
        this.$store.commit("setEmail", user.attributes.email);
        this.$store.commit("setUsername", user.attributes.nickname);
        this.loading = false;
        this.$router.push({ name: "NandoEye" });
      } catch (error) {
        console.log(error);
        this.loading = false;
        this.errorLogin = true;
        this.errorLoginMessage = error.message;
      }
    },
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600&display=swap");
@import "@/css/common.css";

.login-popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #c88af9;
  /* Lighter background color */
  display: flex;
  justify-content: top;
  align-items: center;
  flex-direction: column;
  /* Align children vertically */
}

.logo {
  width: 280px;
  /* Adjusted logo size */
  margin-bottom: 20px;
  /* Negative margin to pull popup up towards the logo */
  margin-top: 20px;
  /* Negative margin to pull popup up towards the logo */
  z-index: 10;
  /* Ensure logo is above the popup */
}

.login-popup {
  font-family: "Montserrat", sans-serif;
  background: #fff;
  /* White background for the popup */
  padding: 10px;
  border-radius: 40px;
  width: 100%;
  max-width: 500px;
  /* Set the max width of the popup */
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.25);
  text-align: center;
  position: relative;
  /* For z-index to take effect */
  z-index: 9;
  /* Ensure popup is below the logo */
}

h2 {
  color: #000;
  /* Black color for the heading */
  margin-bottom: 5px;
  font-family: "Bebas Neue", sans-serif;
  font-size: 54px;
}

p {
  color: #000;
  /* Black color for the paragraph */
  margin-bottom: 16px;
  width: calc(100% - 10px);
  margin-left: 5px;
}

.login-form input {
  width: calc(100% - 40px);
  margin: 10px 20px;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 16px;
}

.checkbox-container {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  text-align: left;
}

.checkbox-container input {
  flex: 0.3;
}

.checkbox-container label {
  color: #6c757d;
  font-size: 14px;
}

.registration-container {
  align-items: center;
  margin: 15px;
}

.registration-container span {
  text-align: center;
  font-size: 14px;
  color: #6c757d;
}

.btn-login {
  background-color: #c88af9;
  /* Button color */
  color: #fff;
  /* White text for buttons */
  border: none;
  padding: 7px 20px;
  margin-bottom: 5px;
  border-radius: 20px;
  cursor: pointer;
  min-width: 250px;
  font-weight: bold;
  font-size: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
}

.btn-login:hover {
  background-color: #472f67;
  /* Darker color on hover */
}

btn-login:disabled,
btn-login[disabled] {
  background-color: #999999;
  color: #666666;
}

.error-message {
  width: 260px;
  margin-top: 10px;
  font-size: 14px;
}
.error-text {
  font-size: 14px;
  font-weight: normal;
}

a {
  color: #000;
}
</style>
