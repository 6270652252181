import mixpanel from "mixpanel-browser";

export default {
  install: (app, { config = {}, token }) => {
    const defaultConfig = {};
    const endConfig = Object.assign(config, defaultConfig);
    mixpanel.init(token, endConfig);
    app.provide("mixpanel", mixpanel);
  },
};
