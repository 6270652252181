import { config } from "@/config";
import vuetify from "@/plugins/vuetify";
import store from "@/store/store";
import mixpanel from "@/utils/mixpanel";
import { Amplify } from "aws-amplify";
import { createApp } from "vue";
import App from "./App.vue";
import awsExports from "./aws-exports";
import router from "./router";

Amplify.configure(awsExports);

const app = createApp(App);
app.use(router);
app.use(store);
app.use(vuetify);
app.use(mixpanel, {
  token: config.MIXPANEL_TOKEN,
  config: {
    debug: config.ENV === 'DEV',
  },
});
app.mount("#app");
