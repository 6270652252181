// Vuetify
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'

const vuetify = createVuetify({
    components,
    directives,
    theme: {
        themes: {
            light: {
                /* Default vuetify colors */
                primary: "#30A237",
                secondary: "#5CE678",
                accent: "#82B1FF",
                error: "#FF4E66",
                info: "#2196F3",
                success: "#4CAF50",
                warning: "#FFC107",

                /* My custom colors */
                nandocolor: "#31a238", 
                nandocomplementary: "a23331", 
                classyvariant: "#3E4A3C", 
                classyvariant1: "#A2AF9F", 
                classyvariant2: "#00A5E9", 
                classyvariant3: "#0071B1", 
                
            }
        },
        options: { customProperties: true }
    },
});

export default vuetify