<template>
  <div class="nando-eye-result" v-if="targetBin">
    <div class="image-result">
      <img v-if="imageUrl" :src="imageUrl" alt="Object" class="object-img" />
    </div>

    <div class="text-result">
      <div class="header">
        <h1 v-if="objectName">{{ this.objectName }}</h1>
        <p>NANDO has recognized your waste.</p>
      </div>

      <div v-if="nandoTip" class="nandoTip-container">
        <p>NANDO TIP:</p>
        <div class="nandoTip-text">
          <p>{{ this.nandoTip }}</p>
        </div>
      </div>

      <div v-if="targetBin" class="target-bin">
        <p>THROW IT HERE</p>
        <div class="target-bin-label">
          <p>{{ this.targetBin }}</p>
        </div>
      </div>

      <div class="button-container">
        <p>WAS IT THE CORRECT ITEM?</p>
        <div style="flex-direction: row">
          <button @click="done(false)" class="responsive-button red-button">
            Wrong
          </button>
          <button @click="done(true)" class="responsive-button done-button">
            Correct
          </button>
        </div>
      </div>
    </div>
  </div>

  <div class="nando-eye-result" v-if="numObjects > 0 && !targetBin">
    <div class="text-result">
      <div class="header">
        <h1 v-if="objectName">{{ this.objectName }}</h1>
        <p>NANDO has recognized your waste.</p>
      </div>

      <div class="nandoTip-container">
        <p>NANDO SAYS:</p>
        <div class="nandoTip-text">
          <!-- <p>I can recognize your item, but I still do not know where to throw it.
            I am always learning and improving, so please come back later.
          </p> -->
          <p>I don’t know where to sort it. Please, check the local guidelines.</p>
        </div>
      </div>

      <div class="button-container">
        <p>WAS IT THE CORRECT ITEM?</p>
        <div style="flex-direction: row">
          <button @click="done(false)" class="responsive-button red-button">
            Wrong
          </button>
          <button @click="done(true)" class="responsive-button done-button">
            Correct
          </button>
        </div>
      </div>
    </div>
  </div>

  <div class="nando-eye-result nando-eye-result-nothing" v-if="numObjects == 0">
    <img src="@/assets/ai_question.png" alt="Sad" class="nothing-img" />

    <div class="header-nothing">
      <h1>SORRY, I COULDN'T<br />RECOGNIZE THE OBJECT</h1>
      <p>You can try again, if you want to</p>
    </div>

    <button @click="done(null)" class="responsive-button done-button">
      Try again
    </button>
  </div>
</template>

<script>
import { config } from '@/config';

export default {
  name: "NandoEye",
  inject: ['mixpanel'],
  data() {
    return {
      model: "",
      objectName: "",
      targetBin: "",
      nandoTip: "",
      weight: null,
      imageUrl: "",
      numObjects: 0,
    };
  },
  created: function () {
    if (!this.$store.state.nandoEyePrediction) {
      this.$router.push({ name: "NandoEye" });
    }
    try {
      this.model = this.$store.state.nandoEyePrediction.model;
      this.objectName = this.$store.state.nandoEyePrediction.eng_name
        ? this.$store.state.nandoEyePrediction.eng_name.toUpperCase()
        : "NOTHING";
      this.targetBin = this.$store.state.nandoEyePrediction.target_bin_en;
      this.weight = this.$store.state.nandoEyePrediction.weight;

      // Display the object image if present
      if (this.$store.state.nandoEyePrediction.image_url) {
        this.imageUrl = this.$store.state.nandoEyePrediction.image_url;
      } 

      this.numObjects = this.$store.state.nandoEyePrediction.num_objects;

      this.nandoTip = this.$store.state.nandoEyePrediction.text_2_eng
        ? this.$store.state.nandoEyePrediction.text_2_eng
        : "";
    } catch (error) {
      console.log(error);
    }
  },
  methods: {
    done(detectionFeedback = null) {
      if (detectionFeedback !== null) {
        this.mixpanel.identify(this.$store?.state?.email)
        this.mixpanel.track('NANDO Demo - Detection user feedback', {
          'Correct Detection': detectionFeedback,
          'Object Name': this.objectName,
          'Environment': config.ENV
        })
      }

      if (this.numObjects > 0) {
        this.$router.push({ name: "Home", query: { showTokens: true } });
      } else {
        this.$router.push({ name: "Home" });
      }
    },
  },
};
</script>

<style scoped>
.header {
  display: flex;
  flex-direction: column;
  position: relative;
  font-size: 2.1vh;
  text-align: left;
}

.header h1 {
  text-transform: uppercase;
}

.header p {
  font-size: 1.3rem;
}

.header-nothing {
  display: flex;
  flex-direction: column;
  position: relative;
  font-size: 3vh;
  text-align: center;
}

@media (max-width: 800px) {
  .header-nothing {
    font-size: 1rem;
  }
}

.header-nothing h1 {
  text-transform: uppercase;
}

.nando-eye-result {
  background-color: white;
  border-radius: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  gap: 3rem;
  padding: 3rem;
}

.nando-eye-result-nothing {
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.image-result {
  flex: 1.5;
  height: 100%;
  max-width: 100%;
  display: flex;
  align-items: center;
}

.object-img {
  max-width: 100%;
  height: auto;
  margin: auto;
  max-height: 400px;
}

.nothing-img {
  width: 60%;
  max-width: 15rem;
}

@media (max-width: 800px) {
  .image-result {
    display: none;
  }
}

.text-result {
  flex-direction: column;
  display: flex;
  flex: 4;
  height: 100%;
  max-height: 100%;
}

.nandoTip-container {
  font-weight: bold;
  font-size: 1.3rem;
  margin-top: 5%;
  text-align: left;
}

.nandoTip-text {
  font-weight: normal;
  max-width: 90%;
  font-size: 1rem;
  text-align: left;
}

.target-bin {
  font-weight: bold;
  font-size: 1.3rem;
  margin-top: 5%;
  text-align: left;
}

.target-bin-label {
  background-color: #f7cb14;
  align-items: center;
  flex-direction: column;
  text-align: center;
  border-radius: 20px;
  max-width: 50%;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
}

.target-bin-label p {
  color: white;
  font-weight: bold;
  text-transform: uppercase;
}

.button-container {
  text-align: left;
  font-size: 1.2rem;
  font-weight: bold;
  margin-top: 5%;
}

.button-container .responsive-button {
  max-width: 120px;
  max-height: 45px;
  margin-top: 2%;
  margin-right: 10%;
}
</style>
