// router.js
import { createRouter, createWebHistory } from 'vue-router';
import Login from '@/views/Login.vue'
import NandoEye from '@/views/NandoEye.vue' 
import NandoEyeTake from '@/views/NandoEyeTake.vue' 
import NandoEyeTakePreview from '@/views/NandoEyeTakePreview.vue' 
import NandoEyeUpload from '@/views/NandoEyeUpload.vue' 
import NandoEyeResult from '@/views/NandoEyeResult.vue' 
import { Auth } from "aws-amplify";

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL), // Use createWebHistory for HTML5 history mode
  routes: [
    {
      path: '/',
      name: 'Home',
      redirect: '/nando-eye',
      meta: { keepQuery: true }
    },
    {
      path: '/login',
      name: 'Login',
      component: Login
    },
    {
      path: '/nando-eye',
      name: 'NandoEye',
      component: NandoEye,
      meta: { requiresAuth: true}
    },
    {
      path: '/nando-eye-take',
      name: 'NandoEyeTake',
      component: NandoEyeTake,
      meta: { requiresAuth: true}
    },
    {
      path: '/nando-eye-take-preview',
      name: 'NandoEyeTakePreview',
      component: NandoEyeTakePreview,
      meta: { requiresAuth: true}
    },
    {
      path: '/nando-eye-upload',
      name: 'NandoEyeUpload',
      component: NandoEyeUpload,
      meta: { requiresAuth: true}
    },
    {
      path: '/nando-eye-result',
      name: 'NandoEyeResult',
      component: NandoEyeResult,
      meta: { requiresAuth: true}
    },
    // ... other routes
  ],
});

function hasQueryParams(route) {
  return !!Object.keys(route.query).length
}

router.beforeEach((to, from, next) => {
  if(to.meta.keepQuery && !hasQueryParams(to) && hasQueryParams(from)){
   next({name: to.name, query: from.query});
 } else {
   next()
 }
})

router.beforeResolve((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    Auth.currentAuthenticatedUser().then(data => {
      if (data && data.signInUserSession) {
        next()
      }
    }).catch(() => {
      next({
        path: '/login',
        query: {
          redirect: to.fullPath,
        }
      });
    });
  } else {
    next()
  }
})

export default router;