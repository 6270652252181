<template>
  <div class="sidebar-user" v-bind:class="{ open: $store.state.isSidebarOpen }">
    <div class="profile-image-placeholder"></div>
    <div class="user-info">
      <h3 style="text-align: center">{{ $store.state.username }}</h3>
      <hr class="sidebar-divider" />
      <!-- Horizontal line -->
      <p>{{ $store.state.email }}</p>
      <hr class="sidebar-divider" />
      <!-- Horizontal line -->
      <p class="token">{{ $store.state.tokens }} Tokens</p>
    </div>
    <div class="divider"></div>
    <button class="info-button" @click="showWhoIsNando = true">
      Who is NANDO?
    </button>
    <hr class="sidebar-divider" />
    <!-- Horizontal line -->
    <button class="info-button" @click="openReLearn">Who is ReLearn?</button>
    <button class="logout-button" @click="showLogout = true">Log out</button>
    <div class="divider2"></div>
  </div>

  <div class="overlay" v-if="$store.state.isSidebarOpen" @click="$store.commit('toggleSidebar')"></div>

  <div class="popup" v-if="showWhoIsNando">
    <div class="popup-content">
      <span class="close-btn" @click="showWhoIsNando = false">&times;</span>


      <div class="popup-inner-container">
        <button @touchEndMethod="currentSlide(currentSlideIndex - 1)" @click="currentSlide(currentSlideIndex - 1)" class="previous-slide-btn" />
        <div style="flex: 1; padding: 20px">
          <div class="popup-header">{{ currentSlideContent.title }}</div>
          <br />
          <p class="popup-text" style="font-weight: bold">
            {{ currentSlideContent.text1 }}
          </p>
          <br />
          <p class="popup-text">
            {{ currentSlideContent.text2 }}
          </p>
        </div>
        <div style="flex: 1; padding: 20px">
          <img :src="currentSlideContent.image" class="popup-img" />
        </div>
        <button @touchEndMethod="currentSlide(currentSlideIndex + 1)" @click="currentSlide(currentSlideIndex + 1)" class="next-slide-btn" />
      </div>
      <!-- Navigation dots (not functional in this example) -->
      <div style="text-align: center">
        <span class="dot" v-for="(_, index) in slideContents" :key="index"
          :class="{ active: currentSlideIndex === index }" @click="currentSlide(index)">
        </span>
      </div>
    </div>
  </div>

  <div class="popup" v-if="showTokens">
    <div class="tokens-popup-content">
      <img src="@/assets/sad_face.svg" alt="Sad" />
      <h1 style="text-align: center">
        YOU HAVE {{ $store.state.tokens }} TOKENS LEFT
      </h1>
      <p style="text-align: center">
        That means you have {{ $store.state.tokens }} more slots to try NANDO.
      </p>
      <p style="text-align: center">
        Use {{ $store.state.tokens > 1 ? "them" : "it" }} carefully.
      </p>
      <button class="responsive-button done-button" @click="showTokens = false">
        I got it
      </button>
    </div>
  </div>

  <div class="popup" v-if="showLogout">
    <div class="logout-popup-content">
      <img src="@/assets/sad_face.svg" alt="Sad" />
      <h1 style="text-align: center">DO YOU REALLY WANT TO GO?</h1>
      <br /><br />
      <button class="responsive-button red-button" @click="logOut">
        Yes, sorry
      </button>
      <br />
      <button class="responsive-button done-button" @click="showLogout = false">
        No, I want to stay
      </button>
    </div>
  </div>
</template>

<script>
import { config } from "@/config";
import { Auth } from "aws-amplify";
import axios from "axios";

export default {
  name: "SidebarUser",
  data() {
    return {
      showWhoIsNando: false,
      showTokens: false,
      showLogout: false,
      currentSlideIndex: 0,
      slideContents: [
        {
          image: "/monitoring.png",
          title: "MONITORING",
          text1: "NANDO is installed in your company's bins.",
          text2:
            "With AI, it constantly monitors and recognises your waste production.",
        },
        {
          image: "/report.png",
          title: "REPORTING",
          text1:
            "NANDO reports the quantity and quality of waste creating a professional sustanability report",
          text2: "",
        },
        {
          image: "learning.png",
          title: "LEARNING",
          text1: "Big problems need everyone efforts!",
          text2: "NANDO uses its data to educate and engage your community",
        },
      ],
    };
  },
  computed: {
    currentSlideContent() {
      console.log("Hello");
      console.log(this.currentSlideIndex);
      console.log(this.slideContents[this.currentSlideIndex]);
      return this.slideContents[this.currentSlideIndex];
    },
  },
  mounted: async function () {
    this.retrieveUserInfo();
    this.retrieveUserTokens();
  },
  methods: {
    async retrieveUserInfo() {
      try {
        var user = await Auth.currentAuthenticatedUser();
        this.$store.commit("setEmail", user.attributes.email);
        this.$store.commit("setUsername", user.attributes.nickname);
      } catch (err) {
        console.log(err);
      }
    },

    async retrieveUserTokens() {
      var endpoint = config.API_URL + "/get-tokens";
      var session = await Auth.currentSession();
      var token = await session.getIdToken().getJwtToken();
      axios
        .get(endpoint, {
          headers: {
            Authorization: token,
          },
        })
        .then((response) => {
          try {
            this.$store.commit("setTokens", response.data["tokens"]);
            if (this.$route.query.showTokens) {
              this.showTokens = true;
            }
          } catch (error) {
            console.log("Error in retrieving tokens: " + error);
          }
        });
    },

    async logOut() {
      await Auth.signOut().then(() => {
        console.log("Successfully logged out");
      });
      this.$router.push("/login");
    },

    openReLearn() {
      window.open("https://www.re-learn.eu/");
    },

    currentSlide(index) {
      if (index >= this.slideContents.length) {
        index = 0;
      } else if (index < 0) {
        index = this.slideContents.length - 1;
      }

      this.currentSlideIndex = index;
    },
  },
};
</script>

<style scoped>
@import "@/css/sidebar.css";

.previous-slide-btn,
.next-slide-btn {
  background-color: transparent;
  color: #305c3b;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  transition-duration: 0.4s;
  border-radius: 5px;
  position: relative;
  margin-top: 3rem;
}

.previous-slide-btn::before, .next-slide-btn::before {
  content: "";
  border: solid #305c3b;
  border-width: 0 5px 5px 0;
  display: inline-block;
  padding: 3px;
  box-sizing: border-box;
  width: 1.5rem;
  height: 1.5rem;
}

.previous-slide-btn::before {
  transform: translateY(-50%) rotate(135deg);
}

.next-slide-btn::before {
  transform: translateY(-50%) rotate(316deg);
}

</style>
