<template>
  <div class="nando-eye-upload-view">
    <div class="content-title">
      <div style="flex: 0.25">
        <button
          @click="$router.push({ name: 'NandoEye' })"
          class="back-button"
        ></button>
      </div>
      <div style="flex: 4.75 1 0%; margin-left: -1rem;">
        <h1>SEE THE MAGIC</h1>
      </div>
    </div>
    <p>Upload your photo or take a picture with your webcam</p>

    <div class="content-container">
      <div class="how-to-container">
        <h1>HOW TO TAKE PICTURES</h1>
        <img src="/scan.png" class="preview-img" />
        <p>Frame the object<br />well in the camera</p>
      </div>

      <div class="vertical-line"></div>

      <div class="city-selector">
        <p>Choose your city</p>
        <select v-model="selectedCity">
          <option v-for="city in cities" :key="city.id" :value="city.id">
            {{ city.name }}
          </option>
        </select>

        <button @click="submit" class="responsive-button try-it-button">
          Take a picture
        </button>

        <div v-if="errorMessage">
          <p v-if="errorMessage" dense outlined dismissible type="error">
            {{ errorMessage }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { config } from "@/config";
import { Auth } from "aws-amplify";
import axios from "axios";

export default {
  name: "NandoEyeTakePreview",
  data() {
    return {
      cities: [],
      selectedCity: this.$store.state.nandoEyeCity ? this.$store.state.nandoEyeCity : 6,
      errorMessage: "",
    };
  },
  mounted: async function () {
    this.retrieveCities();
  },
  methods: {
    async retrieveCities() {
      var endpoint = config.API_URL + "/city-list";
      var session = await Auth.currentSession();
      var token = await session.getIdToken().getJwtToken();
      axios
        .get(endpoint, {
          headers: {
            Authorization: token,
          },
        })
        .catch((error) => {
          console.log(error);
        })
        .then((response) => {
          try {
            this.cities = response.data;
          } catch (error) {
            console.log(error);
          }
        });
    },

    async submit() {
      this.errorMessage = "";
      if (!this.selectedCity) {
        this.errorMessage = "Please select a city";
      } else if (this.$store.state.tokens <= 0) {
        this.errorMessage = "You do not have tokens";
      } else {
        this.$store.commit("setNandoEyeCity", this.selectedCity);
        this.$router.push({ name: "NandoEyeTake" });
      }
    },
  },
};
</script>

<style scoped>
.content-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 1px solid black;
  border-radius: 25px;
  margin: auto;
  width: 50rem;
  min-height: 20rem;
}
@media (max-width: 1230px) {
  .content-container {
    width: 100%;
  }
}
@media (max-width: 800px) {
  .content-container {
    flex-direction: column;
  }
}
.vertical-line {
  border-left: 1px solid #000;
  height: 80%;
}
@media (max-width: 800px) {
  .vertical-line {
    border-top: 1px solid #000;
    width: 80%;
    margin: 3% 10% 3% 10%;
  }
}
.how-to-container {
  margin: auto;
  flex: 5;
  font-size: 0.75rem;
}
.how-to-container h1 {
  font-size: 1.4rem;
  line-height: 1.6rem;
}
.nando-eye-upload-view {
  background-color: white;
  border-radius: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
  flex-direction: column;
  display: flex;
}
.preview-img {
  max-width: 50%;
  max-height: 45vh;
}
@media (max-width: 800px) {
  .preview-img {
    max-width: 40%;
    max-height: 35vh;
  }
}

.city-selector {
  flex: 8;
  font-size: 1.1rem;
  margin: 5%;
  display: flex;
  font-weight: bold;
  text-align: left;
  flex-direction: column; /* Stack children vertically */
}

.city-selector select {
  margin-bottom: 5%;
  padding: 0.25rem;
  border-radius: 25px;
  text-align: center;
  border: 1px solid #000;
  width: 95%;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.try-it-button {
  background-color: #c88af9; /* Example button color */
  color: white;
  align-self: center;
  margin: 5%;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
  font-size: 1rem;
}
</style>
