<template>
  <div class="main-view" v-if="!loading">
    <div class="content-title">
      <div style="flex: 0.25">
        <button @click="$router.push({ name: 'NandoEyeTakePreview' })" class="back-button"></button>
      </div>
      <div style="flex: 4.75 1 0%; margin-left: -1rem;">
        <h1>SCAN YOUR WASTE</h1>
      </div>
    </div>

    <div v-if="!errorMessage" class="video-container">
      <div class="video-canvas-container">
        <video ref="video" class="responsive-video" width="720" height="560" @play="onPlay" autoplay playsinline
          muted></video>
        <canvas ref="canvas" class="canvas" width="720" height="560"></canvas>
      </div>
    </div>

    <div>
      <button @click="capture" class="responsive-button capture-button">
        Capture
      </button>
    </div>

    <div v-if="errorMessage" class="error-message">
      <p v-if="errorMessage" class="error-text">
        {{ errorMessage }}
      </p>
    </div>
  </div>

  <div v-if="loading" class="main-view" style="align-items: center;">
    <br />
    <img class="img" src="@/assets/loading.gif" alt="loading..." width="250" />
    <h1>NANDO IS THINKING</h1>
  </div>
</template>

<script>
import { config } from "@/config";
import { Auth } from "aws-amplify";
import axios from "axios";
import * as faceapi from "face-api.js";

export default {
  name: "NandoEyeTake",
  data() {
    return {
      loading: false,
      base64Image: "",
      errorMessage: "",
      emojis: ["😍", "😎", "🤩", "😻", "🥸"],
    };
  },
  methods: {
    async initFaceApi() {
      await faceapi.nets.tinyFaceDetector.loadFromUri("/models");
      console.log("----> init API ready");
    },
    async startCamera() {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          video: true,
          audio: false,
          facingMode: "environment",
        });
        this.$refs.video.srcObject = stream;
      } catch (error) {
        this.errorMessage = "Error accessing the camera";
        console.error("Error accessing the camera:", error);
      }
    },
    async onPlay() {
      // Setup canvas for drawing
      const video = this.$refs.video;
      const canvas = this.$refs.canvas;
      const emoj = this.emojis[Math.floor(this.emojis.length * Math.random())];

      const displaySize = { width: video.width, height: video.height };
      faceapi.matchDimensions(canvas, displaySize);

      // Start detection loop
      setInterval(async () => {
        try {
          const detections = await faceapi.detectAllFaces(
            video,
            new faceapi.TinyFaceDetectorOptions({
              inputSize: 224,
              scoreThreshold: 0.15,
            })
          );
          if (detections) {
            const resizedDetections = faceapi.resizeResults(
              detections,
              displaySize
            );
            canvas
              .getContext("2d")
              .clearRect(0, 0, canvas.width, canvas.height);

            // Instead of faceapi.draw.drawDetections, draw your coverings here
            // For example, draw a rectangle for each detected face
            for (const detection of resizedDetections) {
              const { x, y, width, height } = detection.box;
              const contex = canvas.getContext("2d");
              contex.font = Math.max(height, width) * 0.075 + "rem serif";
              contex.textAlign = "center";
              contex.textBaseline = "middle";
              contex.fillText(emoj, x + width / 2, y + height / 2);
            }
          }
        } catch (error) {
          console.log(error);
        }
      }, 100);
    },
    async capture() {
      this.loading = true;
      this.errorMessage = "";
      const context = this.$refs.canvas.getContext("2d");
      const video = this.$refs.video;
      const canvas = this.$refs.canvas;

      // Match canvas dimensions with video
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
      context.drawImage(video, 0, 0, canvas.width, canvas.height);
      this.base64Image = canvas.toDataURL("image/png");
      this.$store.commit("setNandoEyeImage", this.base64Image);

      var endpoint = config.API_URL + "/nando-eye-detection";
      var session = await Auth.currentSession();
      var token = await session.getIdToken().getJwtToken();
      var data = {
        bin_regulation_id: this.$store.state.nandoEyeCity,
        image: this.base64Image,
      };

      axios
        .post(endpoint, data, {
          headers: {
            Authorization: token,
          },
        })
        .catch((error) => {
          console.log(error);
        })
        .then((response) => {
          try {
            this.prediction = response.data;
            this.$store.commit("setNandoEyePrediction", response.data);
            this.loading = false;
            this.$router.push({ name: "NandoEyeResult" });
          } catch (error) {
            console.log(error);
            this.loading = false;
            this.errorMessage = "An error occurred during prediction";
          }
        });
    },
  },
  async mounted() {
    if (!this.$store.state.nandoEyeCity) {
      this.$router.push({ name: "NandoEyeTakePreview" });
    }
    await this.startCamera();
    await this.initFaceApi();
  },
  beforeUnmount() {
    const stream = this.$refs.video.srcObject;
    if (stream) {
      stream.getTracks().forEach((track) => track.stop());
    }
  },
};
</script>

<style scoped>
.header {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 75px;
}

p,
h1 {
  color: #000;
  /* Black color for the paragraph */
}

.main-view {
  background-color: white;
  border-radius: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
  height: 100%;
  width: 100%;
  flex-direction: column;
  display: flex;
  flex: 4;
  padding: 3rem;
  min-height: 37rem;
  min-width: 37rem;
}

.loading {
  height: 100%;
  width: 100%;
}

.video-container {
  flex: 0.85;
  /* This will center the child horizontally */
  justify-content: center;
  /* This will center the child vertically */
  align-items: center;
  position: relative;
}

.responsive-video,
.canvas {
  width: auto;
  min-width: 700px;
  max-width: 700px;
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}

.canvas {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  background-color: rgba(255, 0, 0, 0);
}

.capture-button {
  background-color: #2dbb26;
  color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}
</style>
