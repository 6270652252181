
// store.js
import { createStore } from 'vuex'

const store = createStore({
    state: {
        email: "",
        username: "",
        tokens: 0,
        nandoEyePrediction: null,
        nandoEyeImage: "",
        nandoEyeCity: null,
        isSidebarOpen: false,
    },
    mutations: {
        setEmail(state, email) {
            state.email = email;
        },
        setUsername(state, username) {
            state.username = username;
        },
        setTokens(state, tokens) {
            state.tokens = tokens;
        },
        setNandoEyePrediction(state, result) {
            state.nandoEyePrediction = result;
        },
        setNandoEyeCity(state, city) {
            state.nandoEyeCity = city;
        },
        setNandoEyeImage(state, image) {
            state.nandoEyeImage = image;
        },
        toggleSidebar(state) {
            state.isSidebarOpen = !state.isSidebarOpen;
            console.log(state.isSidebarOpen)
        },
    }
});

export default store
