<template>
  <div class="popup-overlay">
    <header-bar />
    <div class="container">
      <sidebar-user class="sidebar" />
      <nando-eye-selection class="main-content" />
    </div>
  </div>
</template>

<script>
import HeaderBar from "@/components/HeaderBar.vue";
import NandoEyeSelection from "@/components/NandoEyeSelection.vue";
import SidebarUser from "@/components/SidebarUser.vue";

export default {
  name: "NandoEye",
  components: {
    HeaderBar,
    SidebarUser,
    NandoEyeSelection,
  },
};
</script>

<style scoped>
@import "@/css/common.css";
</style>